var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-hotarea-editor",class:{'image-hotarea-editor__empty-image': !_vm.hotArea.image}},[_c('div',{staticClass:"image-hotarea-editor__inner"},[_c('div',{staticClass:"image-hotarea-editor__content",on:{"click":_vm.handleOpenDialog}},[(_vm.hotArea.image)?_c('img',{staticClass:"image-hotarea-editor__content-img",attrs:{"src":_vm.hotArea.image.src,"alt":""}}):_vm._e(),_vm._l((_vm.previewAreas),function(area,index){return _c('div',{key:area.__key,staticClass:"image-hotarea-editor__area",style:({
							transform: `translate(${area.x}px, ${area.y}px)`,
							width: area.width + 'px',
							height: area.height + 'px',
							zIndex: _vm.opt_index === index ? 2 : 1
						})},[_c('link-selector',{attrs:{"disabled":""},model:{value:(area.link),callback:function ($$v) {_vm.$set(area, "link", $$v)},expression:"area.link"}})],1)})],2)]),(!_vm.hotArea.image)?_c('image-selector',{model:{value:(_vm.hotArea.image),callback:function ($$v) {_vm.$set(_vm.hotArea, "image", $$v)},expression:"hotArea.image"}}):_vm._e(),_c('el-dialog',{staticStyle:{"cursor":"auto"},attrs:{"title":"热区编辑器","visible":_vm.showDialog,"close-on-click-modal":false,"close-on-press-escape":false,"width":"600px"},on:{"update:visible":function($event){_vm.showDialog=$event}}},[_c('div',{staticClass:"image-hotarea-editor__dialog"},[_c('div',{staticClass:"image-hotarea-editor_steps"},[_c('span',{staticClass:"step-hotarea"},[_c('span',{staticClass:"step-num"},[_vm._v("1")]),_c('span',{staticClass:"step-text"},[_vm._v("添加热区")]),_c('span',[_vm._v("-")])]),_c('span',{staticClass:"step-hotarea"},[_c('span',{staticClass:"step-num"},[_vm._v("2")]),_c('span',{staticClass:"step-text"},[_vm._v("调整热区大小和位置")]),_c('span',[_vm._v("-")])]),_c('span',{staticClass:"step-hotarea"},[_c('span',{staticClass:"step-num"},[_vm._v("3")]),_c('span',{staticClass:"step-text"},[_vm._v("设置关联链接")]),_c('span',[_vm._v("-")])]),_c('span',{staticClass:"step-hotarea"},[_c('span',{staticClass:"step-num"},[_vm._v("4")]),_c('span',{staticClass:"step-text"},[_vm._v("保存生效")]),_c('span')])]),_c('div',{staticClass:"image-hotarea-editor__content",on:{"mousemove":function($event){return _vm.handleMousemove($event)},"mouseenter":_vm.clearOpt}},[_c('img',{staticClass:"image-hotarea-editor__content-img",attrs:{"src":_vm.hotArea.image.src,"alt":""},on:{"load":_vm.handleImageLoad}}),_vm._l((_vm.hotArea.hotAreas),function(area,index){return _c('div',{key:area.__key,staticClass:"image-hotarea-editor__area",style:({
              transform: `translate(${area.x}px, ${area.y}px)`,
              zIndex: index + 1,
              width: area.width + 'px',
              height: area.height + 'px',
              zIndex: _vm.opt_index === index ? 2 : 1
            }),attrs:{"area-index":index},on:{"mousedown":function($event){return _vm.handleMousedown('main', index, $event)},"mouseup":_vm.clearOpt}},[_c('div',{staticClass:"image-hotarea-editor__area-del-icon",on:{"click":function($event){return _vm.handleDeleteArea(area, index)}}},[_vm._v("x")]),_c('div',{staticClass:"image-hotarea-editor__area-opts"},_vm._l((_vm.operateSpaces),function(opt,opt_index){return _c('div',{key:opt_index,staticClass:"opt-area",style:({
                  top: opt.top,
                  right: opt.right,
                  bottom: opt.bottom,
                  left: opt.left,
                  width: opt.width,
                  height: opt.height,
                  cursor: opt.cursor,
                }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.handleMousedown(opt.type, index, $event)}}})}),0),_c('link-selector',{model:{value:(area.link),callback:function ($$v) {_vm.$set(area, "link", $$v)},expression:"area.link"}})],1)})],2)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleAddHotarea}},[_vm._v("添加热区")]),_c('el-button',{on:{"click":_vm.handleSaveHotarea}},[_vm._v("保存")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }