var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"magic-cube"},[(_vm.showEmpty)?_c('div',{staticClass:"magic-cube__empty"}):_c('div',{staticClass:"magic-cube__list",style:({
      width: _vm.cubeStyles.width,
      height: _vm.cubeStyles.height,
      margin: _vm.cubeStyles.margin
    })},_vm._l((_vm.$$FormData.blockList),function(block,index){return _c('div',{key:block.__key,staticClass:"magic-cube__block",style:({
        width: _vm.blockStyles(block, index).width,
        height: _vm.blockStyles(block, index).height,
        top: _vm.blockStyles(block, index).top,
        left: _vm.blockStyles(block, index).left,
        margin: _vm.blockStyles(block, index).margin,
        backgroundImage: _vm.blockStyles(block, index).backgroundImage
      })},[(block.link && block.link.image)?_c('img',{staticClass:"magic-cube__block-img",attrs:{"src":block.link.image.src,"alt":""}}):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }