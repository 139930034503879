<template>
  <div class="shop-info" :class="[`style-${$$FormData.shop_style}`]">
    <div class="shop-info__cover" :style="{backgroundImage: `url(${backgroundImage})`}">
      <div class="shop-info__cover-mask"></div>
    </div>
    <div class="shop-info__inner">
      <div class="shop-info__content">
        <div class="shop-info__logo" :style="{backgroundImage: `url(${shopInfo['shop_logo']})`}"/>
        <div class="shop-info__right-content">
          <h3 class="shop-info__shop-name">{{ shopInfo['shop_name'] }}</h3>
          <p v-if="$$FormData.shop_style === 1" class="shop-info__reduce-content">
            <span v-if="shopInfo['self_operated'] === 1" class="shop-info__reduce-content-tag">自营</span>
            <span class="shop-info__reduce-content-text">666人已收藏，666件商品</span>
            <i class="ri-arrow-right-s-line"></i>
          </p>
          <p v-else class="shop-info__sum-content">
            <a href="javascript:void(0)">
              <span class="shop-info__sum-content-total">666人已收藏</span>
            </a>
            <a href="javascript:void(0)">
              <span class="shop-info__sum-content-total">666件商品</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_Shop from '@/api/shop'
export default {
  name: 'ShopInfoPreview',
  computed: {
    shopInfo() {
      const { shopInfo } = this.$$FormData
      return shopInfo || ''
    },
    backgroundImage() {
      const { background_image } = this.$$FormData
      if (background_image) return background_image.src
      return ''
    }
  },
  async mounted() {
    /**
     * 更新店铺信息
     * 此处更新店铺信息，是为了回显时有最新的店铺信息可以展示
     * 在前端渲染这个店铺模块的时候，也会再去拿一次最新的店铺信息展示
     */
    if (!this.shopInfo) return
    this.$$FormData.shopInfo = await API_Shop.getShopDetail(this.shopInfo['shop_id'])
  }
}
</script>

<style lang="scss" scoped>
.shop-info {
  position: relative;
  font-size: 12px;
  background-color: #fff;
  &__cover {
    position: relative;
    height: 185px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    &-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,.3);
    }
  }
  &__inner {
    position: absolute;
    top: 110px;
    width: 100%;
  }
  &__content {
    position: relative;
    padding-left: 15px;
    overflow: hidden;
  }
  &__logo {
    float: left;
    width: 58px;
    height: 58px;
    overflow: hidden;
    vertical-align: bottom;
    background-color: #fff;
    border: 1px solid #fff;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &__right-content {
    margin-left: 70px;
    h3 {
      max-width: 220px;
      margin-top: 10px;
      color: #fff;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-shadow: 0 1px 15px rgba(0,0,0,.5);
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  &__reduce-content {
    position: relative;
    margin: 8px 15px 0 0;
    padding-right: 10px;
    color: #ffffff;
    cursor: pointer;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    .ri-arrow-right-s-line {
      position: absolute;
      top: 0;
      right: 0;
      color: #ffffff;
      font-size: 14px;
    }
    &-tag {
      display: inline-block;
      height: 14px;
      padding: 0 3px;
      font-size: 12px;
      line-height: 14px;
      vertical-align: middle;
      background-color: #f44;
      border-radius: 1px;
    }
    &-text {
      margin-left: 3px;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  &__sum-content {
    margin-top: 15px;
    line-height: 12px;
    &>a {
      color: #999999;
      &:first-child span::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 12px;
        color: #e5e5e5;
        font-size: 10px;
        content: "|";
      }
    }
    &-total {
      position: relative;
      display: inline-block;
      padding: 0 10px;
      vertical-align: middle;
      &::after {
        padding-left: 0;
        border-left: none;
      }
    }
  }
  &.style-2,
  &.style-3,
  &.style-4 {
    .shop-info__cover-mask {
      background-color: initial;
      background-image: linear-gradient(0deg,#000,transparent);
    }
  }
  &.style-2 {
    height: 238px;
    .shop-info__inner {
      top: 150px;
    }
    .shop-info__logo {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
  &.style-3 {}
  &.style-4 {
    height: 258px;
    .shop-info__cover {
      height: 150px;
    }
    .shop-info__content {
      padding-left: 0;
      text-align: center;
    }
    .shop-info__logo {
      display: inline-block;
      float: none;
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    .shop-info__right-content {
      margin-left: 0;
    }
    .shop-info__shop-name {
      display: inline-block;
      max-width: 100%;
      margin-top: 20px;
      color: #333;
      text-shadow: none;
    }
    .shop-info__sum-content {
      margin-top: 10px;
    }
  }
  &.style-5 {
    height: 250px;
    .shop-info__cover {
      height: 100%;
    }
    .shop-info__inner {
      top: 50px;
    }
    .shop-info__content {
      padding-left: 0;
      text-align: center;
    }
    .shop-info__logo {
      display: inline-block;
      float: none;
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    .shop-info__right-content {
      margin-left: 0;
    }
    .shop-info__shop-name {
      display: inline-block;
      max-width: 100%;
      margin-top: 20px;
      padding-bottom: 10px;
      color: #fff;
      text-shadow: none;
      border-bottom: 2px solid hsla(0,0%,100%,.5);
    }
    .shop-info__sum-content {
      margin-top: 5px;
      a {
        color: #ffffff;
      }
    }
  }
}
</style>
