var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-text-nav",style:({
    paddingLeft: (_vm.$$FormData.page_edge ) * 0.5 + 'px',
    paddingRight: (_vm.$$FormData.page_edge ) * 0.5 + 'px',
    backgroundColor: _vm.$$FormData.background_color,
  })},[(_vm.$$FormData.navList.length)?_c('div',{staticClass:"image-text-nav__nav",class:[_vm.$$FormData.nav_type],style:({
      overflowX: _vm.$$FormData.nav_style === 'fixed' ? 'hidden' : 'auto',
    })},_vm._l((_vm.$$FormData.navList),function(item){return _c('div',{key:item.__key,staticClass:"image-text-nav__item",style:({
        width: (_vm.itemSize ) * 0.5 + 'px',
        color: _vm.$$FormData.text_color
      })},[(_vm.$$FormData.nav_type === 'image-text')?_c('div',{staticClass:"image-text-nav__image",style:({
          width: (_vm.itemSize * _vm.$$FormData.image_scale ) * 0.5 + 'px',
          height: (_vm.itemSize * _vm.$$FormData.image_scale ) * 0.5 + 'px',
          backgroundImage: `url(${item.image.src || _vm.default_image})`,
          borderRadius: (_vm.$$FormData.image_radius ) * 0.5 + 'px',
        })}):_vm._e(),(item.title)?_c('h3',{staticClass:"image-text-nav__title"},[_vm._v(_vm._s(item.title))]):_vm._e()])}),0):_c('div',{staticClass:"image-text-nav__empty"},[_vm._v(" 请添加导航 ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }