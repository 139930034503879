var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"magic-cube-blocks"},[_vm._m(0),_c('div',{staticClass:"body-setting"},[_c('div',{staticClass:"body-setting__tip"},[_vm._v(" "+_vm._s(_vm.$$FormData.magic_style === 'custom' ? '移动鼠标选定布局区域大小' : '选定布局区域，在下方添加图片，建议添加比例一致的图片')+" ")]),_c('div',{staticClass:"magic-cube-blocks__list",style:({height: _vm.getCubeHeight(348 / 2) + 'px'})},_vm._l((_vm.$$FormData.blockList),function(block){return _c('div',{key:block.__key,staticClass:"magic-cube-blocks__block",class:{
          'has-content': !!block.link,
          selected: _vm.currentBlock.__key === block.__key
        },style:({
          width: _vm.blockSize(block).width + 'px',
          height: _vm.blockSize(block).height + 'px',
          top: _vm.blockSize(block).top + 'px',
          left: _vm.blockSize(block).left + 'px',
          backgroundImage: `url(${(block.link && block.link.image) ? block.link.image.src : ''})`
        }),on:{"click":function($event){$event.stopPropagation();return _vm.handleClickBlock(block)}}},[(block.link && block.link.image)?_c('img',{staticClass:"magic-cube-blocks__block-img",attrs:{"src":block.link.image.src,"alt":""}}):_c('div',{staticClass:"magic-cube-blocks__block-tip"},[_vm._v(" "+_vm._s(_vm.blockTip(block))+" "),_c('p',[_vm._v("或同等比例")])])])}),0),(_vm.currentBlock)?_c('div',{staticClass:"magic-cube-blocks__image"},[_c('image-link-editor',{attrs:{"multiple":false,"show-title":false},model:{value:(_vm.currentBlock.link),callback:function ($$v) {_vm.$set(_vm.currentBlock, "link", $$v)},expression:"currentBlock.link"}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-setting"},[_c('div',{staticClass:"header-setting__label"},[_vm._v("魔方布局")])])
}]

export { render, staticRenderFns }