export default {
  name: 'shop-coupon',
  label: {
    title: '店铺优惠券',
    icon: require('@/assets/icon-component-label_ShopCoupon.svg'),
    limit: 1
  },
  decor_type: 'seller',
  sort: 998
}
