var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-search"},[_c('div',{staticClass:"goods-search__inner",style:({
      height: (_vm.$$FormData.frame_height + 20 ) * 0.5 + 'px'
    })},[_c('div',{staticClass:"goods-search__view",style:({backgroundColor: _vm.$$FormData.background_color})},[_c('div',{staticClass:"goods-search__filed",class:[
          `goods-search__filed-${_vm.$$FormData.frame_style}`,
          `goods-search__filed-${_vm.$$FormData.text_position}`
        ],style:({
          backgroundColor: _vm.$$FormData.frame_color,
          color: _vm.$$FormData.text_color
        })},[_c('i',{staticClass:"goods-search__icon ri-search-line"}),_c('label',{staticClass:"goods-search__box",style:({
            height: (_vm.$$FormData.frame_height ) * 0.5 + 'px'
          })},[_c('input',{staticClass:"goods-search__input",style:({color: _vm.$$FormData.text_color}),attrs:{"placeholder":"搜索商品"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }