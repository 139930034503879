var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$$FormData.slicer_type === 'line')?_c('div',{staticClass:"assist-slicer line",style:({
    padding: `0 ${_vm.$$FormData.slicer_padding}px`,
    backgroundColor: _vm.$$FormData.background_color,
  })},[_c('div',{staticClass:"assist-slicer__hr",style:({borderTop: `1px ${_vm.$$FormData.slicer_style} ${_vm.$$FormData.slicer_color}`})})]):_c('div',{staticClass:"assist-slicer",style:({
    height: _vm.$$FormData.blank_height + 'px',
    backgroundColor: _vm.$$FormData.background_color,
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }